<template>
  <md-toolbar md-elevation="0" class="md-transparent" :class="{
    'md-toolbar-absolute md-white md-fixed-top': $route.meta.navbarAbsolute
  }">
    <div class="md-toolbar-row">
      <div class="md-toolbar-section-start md-toolbar-offset">
        <!-- Merge with the Title of the screen by Piouslin's comment -->
        <h3 class="title">{{ $t(`route.${$route.name.toLowerCase()}`) }}</h3>
      </div>
      <div class="md-toolbar-section-end">
        <md-button class="md-just-icon md-round md-simple md-toolbar-toggle" :class="{ toggled: $sidebar.showSidebar }"
          @click="toggleSidebar">
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </md-button>

        <div class="md-collapse">
          <!--div class="md-autocomplete">
            <md-autocomplete
              class="search"
              v-model="selectedEmployee"
              :md-options="employees"
              :md-open-on-focus="false"
            >
              <label v-if="$route.meta.rtlActive">بحث...</label>
              <label v-else>Search111...</label>
            </md-autocomplete>
          </div-->
          <md-list>
            <li class="md-list-item">
              <a href="#" class="md-list-item-router md-list-item-container md-button-clean dropdown">
                <div class="md-list-item-content">
                  <drop-down direction="down">
                    <md-button slot="title" class="md-button md-just-icon md-simple" data-toggle="dropdown">
                      <!--md-icon>notifications</md-icon>
                      <span class="notification">5</span>
                      <p class="hidden-lg hidden-md">Notifications</p-->
                    </md-button>
                    <!--ul class="dropdown-menu dropdown-menu-right">
                      <li><a href="#">Mike John responded to your email</a></li>
                      <li><a href="#">You have 5 new tasks</a></li>
                      <li><a href="#">You're now friend with Andrew</a></li>
                      <li><a href="#">Another Notification</a></li>
                      <li><a href="#">Another One</a></li>
                    </ul-->
                  </drop-down>
                </div>
              </a>
            </li>
            <li class="md-list-item">
              <a href="#" class="md-list-item-router md-list-item-container md-button-clean dropdown">
                <div class="md-list-item-content">
                  <drop-down direction="down">
                    <md-button slot="title" class="md-button md-just-icon md-simple" data-toggle="dropdown">
                      <md-icon>person</md-icon>
                      <p class="hidden-lg hidden-md">Profile</p>
                    </md-button>
                    <ul class="dropdown-menu dropdown-menu-right">
                      <li>
                        {{ $t('Change language') }}
                        <div slot="langs" class="lang-buttons">
                          <md-button v-for="locale in locales" :key="locale" @click="switchLocale(locale)"
                            class="md-primary md-just-icon ms-sm" :class="{ 'md-simple': locale !== activeLocale }">
                            {{ locale }}
                          </md-button>
                        </div>
                      </li>
                      <li>
                        <md-button class="md-simple md-success md-lg" @click="onLogout">
                          {{ $t('label.logout') }}
                        </md-button>
                      </li>
                      <div>
                        Version: {{ $store.state.version }}
                      </div>
                      <li>
                      </li>
                    </ul>
                  </drop-down>
                </div>
              </a>
            </li>
          </md-list>
        </div>
      </div>
    </div>
  </md-toolbar>
</template>

<script>
  export default {
    data() {
      return {
        selectedEmployee: null,
        employees: []
      }
    },
    methods: {
      async onLogout() {
        await this.$store.dispatch('LOGOUT')
        this.$router.push('/login')
      },
      toggleSidebar() {
        this.$sidebar.displaySidebar(!this.$sidebar.showSidebar)
      },
      minimizeSidebar() {
        if (this.$sidebar) {
          this.$sidebar.toggleMinimize()
        }
      },
      switchLocale(code) {
        this.$store.commit('CHANGE_LANG', code)
        this.$router.go();
      }
    },
    computed: {
      me() { 
        return this.$store.state.Login.me 
      },
      localeList() { 
        return this.$store.state.i18nStore.locales 
      },
      user_name() {
        return `${this.me.first_name} ${this.me.last_name}`
      },
      locales() {
        return Object.keys(this.$i18n.messages)
      },
      activeLocale() {
        return this.$i18n.locale
      },
    }
  }
</script>
<style lang="scss" scoped>
h3 {
  text-align: center;
  font-weight: bold;
  margin-top: 5px;
}
</style>